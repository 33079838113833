import React, { useRef, useReducer } from "react";
import { MichelCodecPacker, TezosToolkit, MichelsonMap } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { mat4, vec3 } from "gl-matrix";
import viewIcon from "./viewIcon.png";
import drawIcon from "./drawIcon.png";
import eraseIcon from "./eraseIcon.png";
import layerIcon from "./layerIcon.png";

import { ReactComponent as Spinner } from "./spinner.svg";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const customNetwork = urlParams.get("network");
const customContract = urlParams.get("contract");

const TEZOS_NETWORK = customNetwork ?? "mainnet";

const TEZOS_NODE = TEZOS_NETWORK == "ithacanet" ? "https://ithacanet.ecadinfra.com" : `https://${TEZOS_NETWORK}.api.tez.ie`;

const Tezos = new TezosToolkit(TEZOS_NODE);
const wallet = new BeaconWallet({ name: "Post No Pixels", appUrl: "postnopixels.com", preferredNetwork: TEZOS_NETWORK });

const CANVAS_DRAW_CONTRACT = customContract ?? "KT1WLnWwCfwEAi1pbm2PEArPV7xb5hJsF3p3";

const API_URL = "https://post-no-pixels.appspot.com";

const CANVAS_WIDTH = 256;
const CANVAS_HEIGHT = 256;

const currTime = new Date().getTime();
const combinedCanvasImageURL = `https://storage.googleapis.com/post-no-pixels.appspot.com/${CANVAS_DRAW_CONTRACT}/canvas256.png?ts=${currTime}`;

const isMobile = window.orientation > -1;

Tezos.setWalletProvider(wallet);
Tezos.setPackerProvider(new MichelCodecPacker());

let pressed = false;
let touched = false;
let lastPressPos = null;
let lastPressXY = null;
let canvasScale = 1.0;
let lastTouchDist = 0.0;
let startTouchTime = 0;

let canvasMtx = mat4.create();

let contributorsByLayer = {};

let contributorIDToAddressCache = {};
let contributorIDToMetdataCache = {};

let layerImages = [];

const VIEW_TOOL = 0;
const DRAW_TOOL = 1;
const ERASE_TOOL = 2;

function toHex(str) {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

function fromHex(hex) {
  let str = "";
  try {
    str = decodeURIComponent(hex.replace(/(..)/g, "%$1"));
  } catch (e) {
    str = hex;
    console.log("invalid hex input: " + hex);
  }
  return str;
}

export const Home = () => {
  const [address, setAddress] = useState("");
  const canvasRef = useRef(null);
  const layeredCanvas = useRef(null);
  const canvasContainer = useRef(null);
  const [canvasContext, setCanvasContext] = useState(null);
  const [penImageData, setPenImageData] = useState(null);
  const [drawColor, setDrawColor] = useState("#ffffff");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const profileURLInput = useRef(null);
  const [currentDrawing, setCurrentDrawing] = useState({});
  // const [isInViewMode, setIsInViewMode] = useState(false);
  // const [isInDrawingMode, setIsInDrawingMode] = useState(false);
  // const [isInErasingMode, setIsInErasingMode] = useState(false);
  const [tool, setTool] = useState(DRAW_TOOL);
  const [layerCount, setLayerCount] = useState(0);
  const [layerVisibilityFlags, setLayerVisibilityFlags] = useState(~0);
  const [operationStatus, setOperationStatus] = useState("");
  const [hoveringUserID, setHoveringUserID] = useState(-1);
  const [hoveringUserAddress, setHoveringUserAddress] = useState(null);
  const [hoveringUserUri, setHoveringUserUri] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showAboutInfo, setShowAboutInfo] = useState(false);
  const [showLayers, setShowLayers] = useState(false);

  const [contract, setContract] = useState(null);
  const [contractStorage, setContractStorage] = useState(null);

  const [isSubmittingDrawing, setIsSubmittingDrawing] = useState(false);

  const isInViewMode = tool == VIEW_TOOL;
  const isInDrawingMode = tool == DRAW_TOOL;
  const isInErasingMode = tool == ERASE_TOOL;

  // const [searchParams, setSearchParams] = useSearchParams();

  function drawingLengthReducer(state, action) {
    switch (action.type) {
      case "increment":
        return state + 1;
      case "decrement":
        return state - 1;
      case "clear":
        return 0;
      default:
        throw new Error();
    }
  }

  const [currentDrawingLength, dispatchDrawingLengthChange] = useReducer(drawingLengthReducer, 0);

  const fetchTokenPrice = async (index) => {
    const tokenID = "" + index;

    const token = await contractStorage["tokens"].get(tokenID);

    if (token) {
      currentDrawing[index].contributor = token.contributor;
      currentDrawing[index].layers = token.layers;
    } else {
      currentDrawing[index].contributor = 0;
      currentDrawing[index].layers = -1;
    }
  };

  const addToCurrentDrawing = (index, color) => {
    if (!currentDrawing[index]) {
      currentDrawing[index] = {
        color: color,
        contributor: undefined,
        layers: -1,
      };
      fetchTokenPrice(index);
      dispatchDrawingLengthChange({ type: "increment" });
    }

    currentDrawing[index].color = color;
  };

  const removeFromCurrentDrawing = (index) => {
    if (currentDrawing[index]) {
      delete currentDrawing[index];
      dispatchDrawingLengthChange({ type: "decrement" });
    }
  };

  const setWalletIfExists = async () => {
    const activeAccount = await wallet.client.getActiveAccount();
    if (activeAccount) {
      setAddress(activeAccount.address);
    }
  };

  const connectWallet = async () => {
    const permissions = await wallet.client.requestPermissions({
      network: {
        type: TEZOS_NETWORK,
      },
    });
    setAddress(permissions.address);
  };

  const userIDFromPoint = (layer, x, y) => {
    let index = Math.floor(y) * CANVAS_WIDTH + Math.floor(x);
    if (index > 0 && index < 65536 && contributorsByLayer[layer]) {
      let userID = contributorsByLayer[layer][index * 4];
      userID += contributorsByLayer[layer][index * 4 + 1] * CANVAS_WIDTH;
      userID += contributorsByLayer[layer][index * 4 + 2] * CANVAS_WIDTH * CANVAS_WIDTH;

      let exists = contributorsByLayer[layer][index * 4 + 3] != 0;
      if (exists) {
        return userID;
      }
    }
    return -1;
  };

  const zoom = (delta, px, py) => {
    updateScale(canvasScale + delta, px, py);
  };

  function handleScroll(ev) {
    // ev.preventDefault();
    const px = ev.clientX;
    const py = ev.clientY;

    zoom(ev.deltaY * -0.005, px, py);
  }

  function updateCamera(offsetPos, scaleChange) {
    let mtx = mat4.create();
    let tMtx = mat4.create();
    let sMtx = mat4.create();

    if (scaleChange > 0.001 || scaleChange < -0.001) {
      mat4.fromScaling(sMtx, vec3.fromValues(scaleChange, scaleChange, scaleChange));
    }

    mat4.mul(sMtx, sMtx, canvasMtx);

    mat4.fromTranslation(tMtx, vec3.fromValues(offsetPos.x, offsetPos.y, 0.0));

    mat4.mul(mtx, tMtx, sMtx);
    mat4.copy(canvasMtx, mtx);

    layeredCanvas.current.style.transform = `matrix3d(${mtx[0]}, ${mtx[1]}, ${mtx[2]}, ${mtx[3]}, ${mtx[4]}, ${mtx[5]}, ${mtx[6]}, ${mtx[7]}, ${mtx[8]}, ${mtx[9]}, ${mtx[10]}, ${mtx[11]}, ${mtx[12]}, ${mtx[13]}, ${mtx[14]}, ${mtx[15]})`;
  }

  function updateScale(newScale, px, py) {
    const prevScale = canvasScale;

    // Restrict scale
    canvasScale = Math.min(Math.max(0.25, newScale), 8);

    const scaleChange = canvasScale / prevScale;
    const scaleMult = (canvasScale - prevScale) / prevScale;
    const aspect = window.innerWidth / window.innerHeight;
    let offsetPos = {
      x: -(px - window.innerWidth * 0.5) * scaleMult,
      y: -py * scaleMult,
    };

    updateCamera(offsetPos, scaleChange);
  }

  useEffect(() => {
    // Check if we are connected. If not, do a permission request first.
    setWalletIfExists();
  }, []);

  useEffect(async () => {
    const layerCountTxt = await fetch(`${API_URL}/canvas/${CANVAS_DRAW_CONTRACT}/layerCount`, { cache: "reload" }).then((response) => {
      return response.text();
    });

    let count = parseInt(layerCountTxt) + 1;

    for (let i = 0; i < count; ++i) {
      if (i === 0) {
        layerImages.push(`https://storage.googleapis.com/post-no-pixels.appspot.com/canvas-bg-256.png?ts=${currTime}`);
      } else {
        layerImages.push(`https://storage.googleapis.com/post-no-pixels.appspot.com/${CANVAS_DRAW_CONTRACT}/canvas_layer_${i - 1}.png?ts=${currTime}`);
      }
    }

    try {
      for (let i = 1; i < count; ++i) {
        let contribCanvas = new OffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT);
        let ctx = contribCanvas.getContext("2d");
        const imgBlob = await fetch(`https://storage.googleapis.com/post-no-pixels.appspot.com/${CANVAS_DRAW_CONTRACT}/canvas_layer_contributors_${i - 1}.png?ts=${currTime}`, {
          cache: "reload",
          headers: { Accept: "image/png" },
        }).then((response) => {
          return response.blob();
        });

        const imgBitmap = await createImageBitmap(imgBlob, {
          resizeQuality: "pixelated",
        });

        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(imgBitmap, 0, 0);
        contributorsByLayer[i] = ctx.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT).data;
      }
    } catch (e) {
      console.error(e);
    }

    setLayerCount(count);
  }, []);

  useEffect(async () => {
    if (canvasRef?.current) {
      const ctx = canvasRef.current.getContext("2d", { alpha: true });
      ctx.imageSmoothingEnabled = false;
      setCanvasContext(ctx);

      const imgData = ctx.createImageData(1, 1);
      setPenImageData(imgData);

      const d = imgData.data;
      d[0] = 255;
      d[1] = 255;
      d[2] = 255;
      d[3] = 255;
    }
  }, [canvasRef, setCanvasContext]);

  useEffect(() => {
    canvasContainer.current.addEventListener("wheel", handleScroll);

    updateCamera({ x: 0, y: 0 }, 0);

    return () => {
      canvasContainer.current.removeEventListener("wheel", handleScroll);
    };
  }, [canvasContainer]);

  useEffect(() => {
    setHoveringUserAddress(null);
    setHoveringUserUri(null);
    if (hoveringUserID >= 0) {
      const hoveringAddress = contributorIDToAddressCache[hoveringUserID];
      const hoveringMetadata = contributorIDToMetdataCache[hoveringUserID];
      if (hoveringAddress) {
        setHoveringUserAddress(hoveringAddress);
        setHoveringUserUri(hoveringMetadata);
        //   {
        //   address: hoveringAddress,
        //   uri: uri,
        // });
      } else {
        contractStorage["contributorData"].get(hoveringUserID).then((value) => {
          contributorIDToAddressCache[hoveringUserID] = value.address;
          setHoveringUserAddress(value.address);

          let uri = null;
          if (value?.metadata) {
            const uriBytes = value.metadata.get("uri");
            if (uriBytes) {
              uri = fromHex("" + uriBytes);
            }
          }

          if (!uri && value.address) {
            uri = `https://${TEZOS_NETWORK}.tzkt.io/${value.address}`;
          }
          contributorIDToMetdataCache[hoveringUserID] = uri;
          setHoveringUserUri(uri);
        });
      }
    }
  }, [hoveringUserID]);

  const handleDrawColorChange = (color) => {
    setDrawColor(color);
    const d = penImageData.data;

    if (color.length > 6) {
      d[0] = parseInt(color.substring(1, 3), 16);
      d[1] = parseInt(color.substring(3, 5), 16);
      d[2] = parseInt(color.substring(5, 7), 16);
    } else if (color.length > 3) {
      d[0] = parseInt(color.substring(1, 2).repeat(2), 16);
      d[1] = parseInt(color.substring(2, 3).repeat(2), 16);
      d[2] = parseInt(color.substring(3, 4).repeat(2), 16);
    }

    d[3] = 255;
  };

  const handleDrawColorTextChange = (e) => {
    handleDrawColorChange(e.target.value);
  };

  const drawPixel = (p) => {
    if (isSubmittingDrawing) return;
    if (p.x >= 256 || p.y >= 256 || p.x < 0 || p.y < 0) return;

    canvasContext.putImageData(penImageData, p.x, p.y);
    var index = Math.floor(p.y) * CANVAS_WIDTH + Math.floor(p.x);
    if (index < 0 || index > CANVAS_WIDTH * CANVAS_WIDTH) return;

    addToCurrentDrawing(index, drawColor);
  };

  const erasePixel = (p) => {
    if (isSubmittingDrawing) return;
    const d = penImageData.data;
    d[3] = 0;
    canvasContext.putImageData(penImageData, p.x, p.y);
    d[3] = 255;

    var index = Math.floor(p.y) * CANVAS_WIDTH + Math.floor(p.x);
    if (index < 0) return;

    removeFromCurrentDrawing(index);
  };

  const pointOnLine = (p, a, b) => {
    let ap = {
      x: p.x - a.x,
      y: p.y - a.y,
    };

    let ab = {
      x: b.x - a.x,
      y: b.y - a.y,
    };

    let ab2 = ab.x * ab.x + ab.y * ab.y + 0.00001;
    let ap_ab = (ap.x * ab.x) + (ap.y * ab.y);
    let t = ap_ab / ab2;

    if (t < 0.0) t = 0.0;
    else if (t > 1.0) t = 1.0;

    return {
      x: a.x + ab.x * t,
      y: a.y + ab.y * t,
      t,
    };
  };

  const distanceBetweenPoints = (a, b) => {
    let ab = {
      x: b.x - a.x,
      y: b.y - a.y,
    };
    return Math.sqrt(ab.x * ab.x + ab.y * ab.y);
  }

  const getXY = (px, py) => {
    const rect = canvas.getBoundingClientRect();

    const x = (px - rect.x) / rect.width;
    const y = (py - rect.y) / rect.height;

    const xi = Math.floor(x * CANVAS_WIDTH) + 0.5;
    const yi = Math.floor(y * CANVAS_HEIGHT) + 0.5;

    return {
      x: xi,
      y: yi,
    };
  };

  const handleMouseDown = (ev) => {
    if (showColorPicker) {
      setShowColorPicker(false);
      return;
    }

    const px = ev.clientX;
    const py = ev.clientY;

    const p = getXY(px, py);

    if (!isMobile) {
      if (isInDrawingMode) {
        drawPixel(p);
      } else if (isInErasingMode) {
        erasePixel(p);
      }
    }

    lastPressPos = p;
    lastPressXY = p;

    pressed = true;

    const aspect = window.innerWidth / window.innerHeight;
    let offsetPos = {
      x: px - window.innerWidth * 0.5,
      y: py - window.innerHeight * aspect * 0.5,
    };

    lastPressPos = offsetPos;
  };

  const handleMouseUp = (ev) => {
    const px = ev.clientX;
    const py = ev.clientY;

    // drawPixel(getXY(px, py))

    pressed = false;
  };

  const updateMove = (px, py, forceUpdateCamera = false) => {
    const p = getXY(px, py);

    if (isInViewMode) {
      let userID = -1;
      for (let i = 1; i < layerCount; ++i) {
        let uid = userIDFromPoint(i, p.x, p.y);
        if (uid === -1) break;
        if (((layerVisibilityFlags >> i) & 1) === 1) {
          userID = uid;
        }
      }
      setHoveringUserID(userID);
    } else {
      setHoveringUserID(-1);
    }

    setIsDragging(pressed && lastPressPos);

    if (pressed && lastPressPos) {
      const aspect = window.innerWidth / window.innerHeight;
      let offsetPos = {
        x: px - window.innerWidth * 0.5,
        y: py - window.innerHeight * aspect * 0.5,
      };

      if (isInViewMode || forceUpdateCamera) {
        updateCamera({ x: offsetPos.x - lastPressPos.x, y: offsetPos.y - lastPressPos.y }, 0);
      } else {
        const step = 0.1;
        const prevXY = lastPressXY;
        let startP = { x: prevXY.x, y: prevXY.y };
        let endP = { x: p.x, y: p.y };

        if (p.x < prevXY.x) {
          startP.x = p.x;
          endP.x = prevXY.x;
        }

        if (p.y < prevXY.y) {
          startP.y = p.y;
          endP.y = prevXY.y;
        }

        if (startP.x > 0.5) {
          startP.x -= 5;
        }
        if (startP.y > 0.5) {
          startP.y -= 5;
        }

        if (endP.x < 255) {
          endP.x += 5;
        }
        if (endP.y < 255) {
          endP.y += 5;
        }

        for (let xx = startP.x; xx <= endP.x; ++xx) {
          for (let yy = startP.y; yy <= endP.y; ++yy) {
            const cP = { x: xx, y: yy };
            const pL = pointOnLine(cP, prevXY, p);

            const d = distanceBetweenPoints(pL, cP);

            if (d <= 0.5 && isInDrawingMode) {
              drawPixel(cP);
            } else if ((d <= 0.5 || (d < 5.0 && isMobile)) && isInErasingMode) {
              erasePixel(cP);
            }
          }
        }
        // drawPixel(p);
      }

      lastPressPos = offsetPos;
      lastPressXY = p;
    }
  };

  const handleMouseMove = (ev) => {
    const px = ev.clientX;
    const py = ev.clientY;
    updateMove(px, py);
  };

  const handleTouchDown = (ev) => {
    ev.preventDefault();
    const touches = ev.touches;

    lastTouchDist = -1;
    pressed = true;

    let px = touches[0].clientX;
    let py = touches[0].clientY;

    if (touches.length > 1) {
      for (let i = 1; i < 2; ++i) {
        px += touches[i].clientX;
        py += touches[i].clientY;
      }

      px = px * 0.5;
      py = py * 0.5;
    }

    const p = getXY(px, py);

    // if (&& isInDrawingMode) {
    //   drawPixel(p);
    // }

    lastPressPos = p;
    lastPressXY = p;

    pressed = true;

    const aspect = window.innerWidth / window.innerHeight;
    let offsetPos = {
      x: px - window.innerWidth * 0.5,
      y: py - window.innerHeight * aspect * 0.5,
    };

    lastPressPos = offsetPos;

    startTouchTime = performance.now();
  };

  const handleTouchUp = (ev) => {
    ev.preventDefault();
    pressed = false;
  };

  const handleTouchMove = (ev) => {
    ev.preventDefault();
    const currTime = performance.now();

    const touches = ev.touches;

    let avgX = 0;
    let avgY = 0;

    if (touches.length == 2) {
      let distX = touches[1].clientX - touches[0].clientX;
      let distY = touches[1].clientY - touches[0].clientY;
      avgX = (touches[0].clientX + touches[1].clientX) * 0.5;
      avgY = (touches[0].clientY + touches[1].clientY) * 0.5;

      let touchDist = Math.sqrt(distX * distX + distY * distY);
      let zoomDelta = 0;
      if (lastTouchDist >= 0) {
        zoomDelta = touchDist - lastTouchDist;
      }

      lastTouchDist = touchDist;

      if (zoomDelta !== 0) {
        zoom(zoomDelta * 0.01, avgX, avgY);
      }
      updateMove(avgX, avgY, true);
    } else if (touches.length == 1) {
      if (isInDrawingMode) {
        if (currTime - startTouchTime < 100) {
          return;
        }
      }
      avgX = touches[0].clientX;
      avgY = touches[0].clientY;
      updateMove(avgX, avgY);
    }
  };

  const handleToggleShowColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleToggleShowDisconnectButton = () => {
    setShowProfileOptions(!showProfileOptions);
  };

  const handleToggleLayerVisibility = (layer) => {
    let layerVisibility = layerVisibilityFlags;
    // toggles only the specified layer
    // const justLayer = 1 << layer;
    // const currentWithout = ~justLayer & layerVisibility;
    // layerVisibility = (justLayer ^ layerVisibility) | currentWithout;

    // turns on all layers at and below selected
    layerVisibility = ~(~0 << (layer + 1));

    setLayerVisibilityFlags(layerVisibility);
  };

  const handleToggleShowLayers = () => {
    setShowLayers(!showLayers);
  };

  useEffect(async () => {
    if (canvasContext) {
      // const imgBlob = await fetch(API_URL + "/canvas", { cache: "reload" }).then((response) => {
      //   return response.blob();
      // });

      // // const imgBlob = await fetch("http://127.0.0.1:8887/canvas.png").then((response) => {
      // //   return response.blob();
      // // })

      // const imgBitmap = await createImageBitmap(imgBlob, {
      //   resizeQuality: "pixelated",
      // });

      canvasContext.imageSmoothingEnabled = false;
      // canvasContext.drawImage(imgBitmap, 0, 0);

      //imgBitmap.close();

      const aspect = window.innerWidth / window.innerHeight;
      if (aspect > 1.0) {
        updateScale(1.0 / aspect, window.innerWidth / 2, 0);
      }
    }
  }, [canvasContext]);

  const handleMouseClickLink = (ev) => {
    window.open(hoveringUserUri, "_blank", "noopener");
  };

  useEffect(() => {
    if ((hoveringUserAddress || hoveringUserUri) && !isDragging && isInViewMode) {
      document.body.style.cursor = "pointer";
      canvasRef.current.addEventListener("click", handleMouseClickLink);
    } else {
      document.body.style.cursor = "";
    }

    return () => {
      canvasRef.current.removeEventListener("click", handleMouseClickLink);
    };
  }, [hoveringUserAddress, hoveringUserUri, isDragging, tool]);

  useEffect(() => {
    if (canvasContext && contractStorage) {
      canvasContainer.current.addEventListener("mousedown", handleMouseDown);
      canvasContainer.current.addEventListener("mouseup", handleMouseUp);
      canvasContainer.current.addEventListener("mousemove", handleMouseMove);

      canvasContainer.current.addEventListener("touchstart", handleTouchDown);
      canvasContainer.current.addEventListener("touchend", handleTouchUp);
      canvasContainer.current.addEventListener("touchmove", handleTouchMove);

      return () => {
        canvasContainer.current.removeEventListener("mousedown", handleMouseDown);
        canvasContainer.current.removeEventListener("mouseup", handleMouseUp);
        canvasContainer.current.removeEventListener("mousemove", handleMouseMove);

        canvasContainer.current.removeEventListener("touchstart", handleTouchDown);
        canvasContainer.current.removeEventListener("touchend", handleTouchUp);
        canvasContainer.current.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [canvasContext, currentDrawing, drawColor, tool, layerCount, layerVisibilityFlags, isSubmittingDrawing, contractStorage, showColorPicker]);

  useEffect(async () => {
    const contract = await Tezos.wallet.at(CANVAS_DRAW_CONTRACT);
    setContract(contract);

    const storage = await contract.storage();
    setContractStorage(storage);
  }, []);

  // const allocate = async () => {
  //   const contract = await Tezos.wallet.at(CANVAS_DRAW_CONTRACT);
  //   contract.storage().then((storage) => {
  //     console.warn("got storage: ", storage);
  //     storage["tokens"].get(0).then((value) => {
  //       console.warn("got value: " + value);
  //     });
  //   });
  //   console.log("got contract: ", contract);
  //   try {
  //     console.warn(contract.methods);
  //     console.warn(contract);
  //     console.warn(contract.entrypoints);
  //     const result = await contract.methods.allocate(65536).send();
  //     console.log("result: ", result);
  //   } catch (error) {
  //     console.log(`The contract call failed and the following error was returned:`, error?.data[1]?.with?.string);
  //   }
  // };

  const submitDrawing = async (tokens) => {
    try {
      setIsSubmittingDrawing(true);
      setOperationStatus("calculating");
      let tokenOwnerCounts = {};
      let unknownTokenContributors = [];
      Object.entries(tokens).forEach(([key, value]) => {
        if (value.contributor !== undefined) {
          tokenOwnerCounts[key] = value.layers;
        } else {
          unknownTokenContributors.push(key);
        }
      });

      if (unknownTokenContributors.length > 0) {
        const unknownTokens = await contractStorage["tokens"].getMultipleValues(unknownTokenContributors);

        Object.entries(unknownTokens).forEach(([key, value]) => {
          if (value) {
            tokenOwnerCounts[key] = value.layers;
          } else {
            tokenOwnerCounts[key] = -1;
          }
        });
      }

      let estimatedPrice = 0;
      Object.entries(tokenOwnerCounts).forEach(([key, value]) => {
        if (!key) {
        } else if (value === -1) {
          estimatedPrice += 1; // minting price
        } else {
          const addition = 100000 * Math.pow(2, value);
          estimatedPrice += addition;
        }
      });

      console.log("estimated price: " + estimatedPrice);

      // let drawParams = {
      //   to_: address,
      //   txs: [],
      // };

      let drawTxs = [];

      Object.entries(tokens).forEach(([key, value]) => {
        drawTxs.push({
          token_id: key,
          color: value.color.substring(1),
        });
      });

      // const estimateOp = await contract.methods.draw(drawParams).toTransferParams({});
      // const { gasLimit, storageLimit, suggestedFeeMutez } = await Tezos.estimate.transfer(estimateOp);

      setOperationStatus("submitting");
      let result = null;
      result = await contract.methods.draw(address, drawTxs).send({
        amount: estimatedPrice / 1000000,
        // storageLimit: storageLimit,
        // gasLimit: gasLimit,
        // fee: suggestedFeeMutez,
      });

      fetch(`${API_URL}/canvas/${CANVAS_DRAW_CONTRACT}/update`, {
        method: "POST",
      });

      setOperationStatus("confirming");
      await result.confirmation();

      await fetch(`${API_URL}/canvas/${CANVAS_DRAW_CONTRACT}/update`, {
        method: "POST",
      });

      setCurrentDrawing({});
      dispatchDrawingLengthChange({ type: "clear" });
    } catch (e) {
      console.error(e);
    } finally {
      setOperationStatus("");
      setIsSubmittingDrawing(false);
    }
  };

  const submitCustomURL = async () => {
    if (!profileURLInput.current) {
      console.error("profile url input not set");
      return;
    }

    const urlHex = toHex(profileURLInput.current.value);
    let result = null;
    console.warn("address: ", address);
    const newMapfromLiteral = MichelsonMap.fromLiteral({
      uri: urlHex,
    });
    result = await contract.methods.set_contributor_metadata(address, newMapfromLiteral).send();

    await result.confirmation();
    console.log("confirmed");
  };

  let layersVisible = [];
  for (let i = 0; i < layerCount; ++i) {
    const visible = !isInViewMode || ((layerVisibilityFlags >> i) & 1) === 1;
    layersVisible.push(visible);
  }

  return (
    <div>
      <div
        className="canvasContainer"
        ref={canvasContainer}
        onContextMenu={(ev) => {
          ev.preventDefault();
          return false;
        }}
      >
        <div className="layeredCanvas" ref={layeredCanvas}>
          {layerCount > 0 && showLayers ? (
            layerImages.map((value, index) => {
              return <img key={`image_layer_${index}`} hidden={!layersVisible[index]} className="imageLayer" src={value}></img>;
            })
          ) : (
            <img className="imageLayer" src={combinedCanvasImageURL}></img>
          )}
          <canvas ref={canvasRef} id="canvas" width={"" + CANVAS_WIDTH} height={"" + CANVAS_HEIGHT}></canvas>
        </div>
      </div>
      <div className="topbar">
        <div
          className="topbarRow"
          style={{
            background: "black",
          }}
        >
          <div></div>
          {address ? (
            <>
              <button onClick={handleToggleShowDisconnectButton}>{address}</button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  connectWallet();
                }}
              >
                connect wallet
              </button>
            </>
          )}
          <div>
            <button
              onClick={() => {
                setShowAboutInfo(!showAboutInfo);
              }}
            >
              about
            </button>
          </div>
        </div>
        {address && !showColorPicker && (
          <div className="topbarRow">
            {/* <div className="colorPicker">{showColorPicker && <SketchPicker disableAlpha color={drawColor} onChangeComplete={handleDrawColorChange} />}</div> */}
            <div></div>
            <div>
              {showProfileOptions && (
                <div className="profileOptions">
                  <div>
                    <input ref={profileURLInput} type="text" placeholder="custom url"></input>
                    <button
                      onClick={() => {
                        submitCustomURL();
                      }}
                    >
                      submit
                    </button>
                  </div>
                  <button
                    style={{
                      color: "red",
                    }}
                    onClick={() => {
                      wallet.clearActiveAccount();
                      setAddress("");
                    }}
                  >
                    disconnect
                  </button>
                </div>
              )}
            </div>
            <div></div>
          </div>
        )}
      </div>
      {
        <div>
          <div className="toolbar">
            {/* <span>
            <input type="checkbox" onChange={handleToolModeToggleButton} />
          </span> */}
            <div className="toolbarGroup">
              <button
                className={isInViewMode ? "toggleDrawingButton selected" : "toggleDrawingButton"}
                onClick={() => {
                  setTool(VIEW_TOOL);
                }}
              >
                <img className="toolbarButtonIcon" src={viewIcon} />
                {/* ⎈ */}
              </button>
              <button
                className={isInDrawingMode ? "toggleDrawingButton selected" : "toggleDrawingButton"}
                onClick={() => {
                  setTool(DRAW_TOOL);
                }}
              >
                <img className="toolbarButtonIcon" src={drawIcon} />
              </button>
              <button
                className={isInErasingMode ? "toggleDrawingButton selected" : "toggleDrawingButton"}
                onClick={() => {
                  setTool(ERASE_TOOL);
                }}
              >
                <img className="toolbarButtonIcon" src={eraseIcon} />
              </button>
            </div>
            {isInDrawingMode && <button className="changeColorButton" style={{ background: drawColor }} onClick={handleToggleShowColorPicker}></button>}
            <div className="colorPicker">
              {showColorPicker && (
                <>
                  <input className="colorPickerText" type="text" value={drawColor} onChange={handleDrawColorTextChange}></input>
                  <HexColorPicker color={drawColor} onChange={handleDrawColorChange} />
                </>
              )}
            </div>

            {/* <span>
            <button onClick={handleToggleShowDisconnectButton}>{address}</button>
          </span> */}
          </div>
        </div>
      }
      {
        <div className="layersControlBar" style={{ visibility: isInViewMode ? "visible" : "hidden" }}>
          <button onClick={handleToggleShowLayers} className={showLayers ? "toggleDrawingButton selected" : "toggleDrawingButton"} style={{ width: "48px" }}>
            <img className="toolbarButtonIcon" src={layerIcon} />
          </button>
          <div className="layerControlSelectionSection">
            {showLayers &&
              layerImages.map((value, index) => {
                return (
                  <button
                    className={layersVisible[index] ? "layerControlButton selected" : "layerControlButton"}
                    key={`image_layer_select_${index}`}
                    onClick={() => handleToggleLayerVisibility(index)}
                  >
                    <img className="layerControlImage" src={value}></img>
                    {index > 0 && <span className="layerControlLabel">{index}</span>}
                  </button>
                );
              })}
          </div>
        </div>
      }
      {currentDrawingLength > 0 && !isInViewMode && (
        <div>
          {currentDrawingLength > 256 && <div className="warningLabel">drawing a large amount of pixels in one transaction will be more likely to fail</div>}
          <div className="controlBar">
            {isSubmittingDrawing ? (
              <div className="confirmSpinner">
                <Spinner
                  style={{
                    width: "32px",
                    height: "100%",
                  }}
                />
                {operationStatus}...
              </div>
            ) : (
              <>
                {" "}
                <div>
                  <span style={currentDrawingLength > 256 ? { color: "red" } : {}}>{currentDrawingLength}</span>/256
                </div>
                <div>
                  {address ? (
                    <button
                      className="submitButton"
                      onClick={() => {
                        submitDrawing(currentDrawing);
                      }}
                    >
                      submit
                    </button>
                  ) : (
                    <button
                      className="submitButton"
                      onClick={() => {
                        connectWallet();
                      }}
                    >
                      connect wallet
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {hoveringUserAddress && isInViewMode && (
        <div className="hoveringUserInfo">
          <b>{hoveringUserAddress}</b>
          <div>{hoveringUserUri}</div>
        </div>
      )}

      {showAboutInfo && (
        <div className="aboutInfoContainer">
          <div className="aboutInfo">
            <h1>Post No Pixels</h1>
            <button className="closeButton" onClick={() => setShowAboutInfo(false)}>
              x
            </button>
            <div className="aboutInfoText">
              <h3>What is it?</h3>
              <div>An experimental Tezos drawing dApp.</div>
              <br />
              <h3>How does it work?</h3>
              <div>Connect your Tezos wallet, select the drawing tool, select your color, and click + drag on the canvas.</div>
              <br />
              <h3>How much does it cost?</h3>
              <div>
                Drawing onto the first layer cost 0.000001 TEZ per pixel (but has a larger storage fee, which should be around 0.02 TEZ per pixel).
                <br />
                <br />
                The second layer starts at 0.1 TEZ per pixel, and doubles for each layer after that, with 80% going to the contributor who drew on the layer below.
                <br />
                <br />
                Additionally, there is an additional single time storage fee as a first time contributor, which shoud be around 0.05 TEZ.
              </div>
              <br />
              <h3>How do I set a custom url?</h3>
              <div>Click on your wallet address at the top, fill in the url field, and click submit.</div>
              <br />
              <br />
              <br />
              <br />
              <div>
                <div>
                  <b>contract address: </b>
                  <a href={`https://${TEZOS_NETWORK}.tzkt.io/${CANVAS_DRAW_CONTRACT}`}>{CANVAS_DRAW_CONTRACT}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <img
          src={"http://localhost:8080/canvas"}
        /> */}
    </div>
  );
};
